import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'

import PageWrapper from '../../components/PageWrapper'
import { Section, Box } from '../../components/Core'

const BoxStyled = styled(Box)`
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`

const ShadowBox = styled(BoxStyled)`
  background: rgba(0, 0, 0, 0.5);
`

const ProjectPage = () => {
  return (
    <PageWrapper headerDark footerDark currentPage='project-github'>
      <BoxStyled>
        <ShadowBox>
          <Section className='pb-0'>
            <Container>
              <Row className='justify-content-center text-center'>
                <Col lg='6'></Col>
              </Row>
            </Container>
          </Section>
        </ShadowBox>
      </BoxStyled>
    </PageWrapper>
  )
}
export default ProjectPage
